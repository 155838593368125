import React, { Component } from "react";
import { Card, Grid, Form, Table, Button, Icon } from "tabler-react";
import { connect } from "react-redux";
import {
  actionCleanClientDetails,
  actionUpdateClientPayments,
  actionProcessPayments,
  actionCancelPayment,
} from "redux/actions/PaymentsActions";
import { actionGetClient } from "redux/actions/ClientsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faTrash,
  faBan,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { actionGetClientSinglePagination } from "redux/actions/PaymentsActions";
import { actionGetFeesByClientAndRange } from "redux/actions/FeesActions";
import { SaveButton } from "components/buttons/SaveButton";
import { CancelButton } from "components/buttons/CancelButton";
import { SharedButton } from "components/buttons/SharedButton";
import { Helpers } from "helpers/Helpers";
import { actionMessage } from "redux/actions/SharedActions";
import { CONSTANTS_MESSAGE } from "constants/ConstantsMessages";
import SearchClient from "components/SearchClient";
import Modal from "components/Modal";
import ModalConfirm from "components/ModalConfirm";

import PaymentsList from "pages/payments/PaymentsList";
import { CONSTANTS } from "constants/Constants";

class BillingUpdate extends Component {
  constructor(props) {
    super(props);
    const dToday = Helpers.ConvertTZ(new Date());
    this.state = {
      oClient: null,
      bValidForm: false,
      oErrors: {},
      aMonths: [],
      timeOutId: null,
      clientIndex: 1,
      nMonth: dToday.getMonth() + 1,
      nYear: dToday.getFullYear(),
      disableUpFeeButton: true,
      disableDownFeeButton: false,
      aFees: [],
      isModalCreatePayment: false,
      nPaymentAmount: 0,
      isModalConfirmCreatePayment: false,
      isModalServicesView: false,
      aFeeServices: [],
      isModalPaymentsOpen: false,
      aPayments: [],
      bShowModalReason: false,
      sReason: "",
      nPaymentId: null,
      sSearchType: "BILLING",
      conutDown: "",
    };
  }

  componentDidMount = () => {
    if (
      this.props.match.params.id !== undefined &&
      this.state.oClient === null
    ) {
      this.props.getClientsSinglePagination(this.props.match.params.id, null);
    }
    //SE COMENTA POR SI SE VUELVE A PEDIR LA FUNCIONALIDAD Juan Andres Vega 18/06/2021
    //window.addEventListener("keydown", this._handleArrowFunctions);
  };

  //SE COMENTA POR SI SE VUELVE A PEDIR LA FUNCIONALIDAD Juan Andres Vega 18/06/2021
  // _handleArrowFunctions = (e) => {
  //   const { isModalCreatePayment: bShowModalCreatePayment } = this.state;
  //   switch (e.keyCode) {
  //     case 37:
  //       if (!bShowModalCreatePayment)
  //         this.searchByIndex(this.state.clientIndex - 1);
  //       break;
  //     case 39:
  //       if (!bShowModalCreatePayment)
  //         this.searchByIndex(this.state.clientIndex + 1);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  //detectar cambios en el state
  componentDidUpdate = () => {
    if (
      this.props.propsPayment.oClient !== null &&
      this.props.propsPayment.oClient !== undefined &&
      (this.state.oClient === null ||
        this.props.propsPayment.oClient.id !== this.state.oClient.id)
    ) {
      const { oClient, clientIndex } = this.props.propsPayment;
      const oClientParsed = JSON.parse(JSON.stringify(oClient));
      this.setState({ oClient: oClientParsed, clientIndex });
      this.props.history.replace({ pathname: `/billing/edit/${oClient.id}` });
      this.props.getFeesByClientAndRange(oClient.id, null, null);
    }
    if (
      this.props.propsFee.aFees !== [] &&
      this.props.propsFee.aFees !== this.state.aFees
    ) {
      const { aFees } = this.props.propsFee;
      this.setState({ aFees });
      this.setState({ conutDown: this.props.propsFee.aFees.length });
    }

    if (this.state.conutDown > this.props.propsFee.aFees.length) {
      this.props.propsFee.dDownDate = null;
    }
  };

  componentWillUnmount = () => {
    this.setState({ oClient: null, clientIndex: 1 });
    this.props.CleanClientDetails();
    window.removeEventListener("keydown", this._handleArrowFunctions);
  };

  loadConfirmGenerateFeesModal = () => {
    this.setState({ isModalConfirmGenerateFeesModal: true });
  };

  _handleCloseConfirmGenerateFeesModal = () => {
    window.location.reload();
    this.setState({
      isModalConfirmGenerateFeesModal: false,
    });
  };

  loadCancelGenerateFeesModal = () => {
    this.setState({ isModalCancelGenerateFeesModal: true });
  };

  _handleCloseCancelGenerateFeesModal = () => {
    window.location.reload();
    this.setState({
      isModalCancelGenerateFeesModal: false,
    });
  };

  _updateDebt = () => {
    if (this.props.propsPayment.oClient.debt !== this.state.oClient.debt) {
      // # Confirmacion para cambio de importe de la DEUDA
      return this.loadConfirmGenerateFeesModal();
    }
  };

  _stopSave = () => {
    if (this.props.propsPayment.oClient.debt !== this.state.oClient.debt) {
      return this.loadCancelGenerateFeesModal();
    } else {
      this._save();
    }
  };

  _save = async () => {
    let oClient = this.state.oClient;
    console.log(oClient);
    if (oClient.pay_method === "Facilpago") {
      if (!oClient.card_branch || !oClient.card_number) {
        return alert("Debe ingresar los datos de la tarjeta de crédito");
      }
    } else if (oClient.pay_method !== "Facilpago") {
      oClient.card_branch = null;
      oClient.card_number = null;
      oClient.card_owner_ci = null;
      oClient.card_owner_name = null;
      this.setState({ oClient });
    }

    if (
      !Helpers.isset(oClient.email_billing) ||
      Helpers.validEmail(oClient.email_billing)
    ) {
      oClient.aFees = this.state.aFees;
      this.props.update(oClient);
    } else {
      //mandar mensaje de correo invalido
      this.props.sendMessage(
        "El campo Email para envío de factura no es válido.",
        CONSTANTS_MESSAGE.TYPE_ERROR
      );
      return;
    }
  };

  _handleFeeChange = (e, nIdx) => {
    const { name, value } = e.target;
    let aFees = this.state.aFees;
    aFees[nIdx][name] = value;
    this.setState({ aFees });
  };

  _handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    let oClient = this.state.oClient;
    oClient[name] = value;
    this.setState({ oClient });
    console.log(this.state.oClient);
  };

  _handleIndexChange = (event) => {
    let { value } = event.target;
    clearTimeout(this.state.timeOutId);
    let timeOutId = setTimeout(() => {
      this.searchByIndex(value);
    }, 800);
    this.setState({ timeOutId, clientIndex: value });
  };

  searchByIndex = (nIndex) => {
    this.props.getClientsSinglePagination(null, nIndex == 0 ? 1 : nIndex);
  };

  loadServicesViewModal = (aFeeServices) => {
    this.setState({ isModalServicesView: true, aFeeServices });
  };

  _handleCloseServicesViewModal = () => {
    this.setState({
      aFeeServices: [],
      isModalServicesView: false,
    });
  };

  openPaymentsModal = (aPayments) => {
    this.setState({ isModalPaymentsOpen: true, aPayments });
  };
  closePaymentsModal = () => {
    this.setState({
      isModalPaymentsOpen: false,
    });
  };

  cancelPayment = (nPaymentId) => {
    this.setState({
      bShowModalReason: true,
      sReason: "",
      nPaymentId: nPaymentId,
    });
  };

  handleCancelPayment = () => {
    const { sReason, nPaymentId, oClient } = this.state;
    this.props.cancelPayment(nPaymentId, sReason, oClient.id);
    this.setState({ bShowModalReason: false, sReason: "", nPaymentId: null });
  };

  downloadInvoice = (nOrderId) => {
    window.open(
      `${CONSTANTS.API_URL}clients/downloadInvoice/${nOrderId}`,
      "_blank"
    );
  };

  renderModalReason = () => {
    const { bShowModalReason, sReason } = this.state;
    return (
      <Modal
        isOpen={bShowModalReason}
        modalTitle="Nota de crédito"
        handleActionModal={this.handleCancelPayment}
        customStyles={{ width: "550px" }}
        handleCloseModal={() =>
          this.setState({ bShowModalReason: false, nPaymentId: null })
        }
      >
        <p>Razón para generar nota de crédito</p>
        <Form.Textarea
          name="observation"
          placeholder="Factura generada por equivocación"
          rows={6}
          onChange={(oEvent) => this.setState({ sReason: oEvent.target.value })}
        >
          {sReason}
        </Form.Textarea>
      </Modal>
    );
  };

  renderServicesViewModal = () => {
    const { aFeeServices } = this.state;
    return (
      <Modal
        isOpen={this.state.isModalServicesView}
        handleCloseModal={this._handleCloseServicesViewModal}
        modalHideAction={true}
        modalCancelCaption="Cerrar"
        modalTitle="Servicios"
        customStyles={{
          width: "1000px",
        }}
      >
        <Table className="table-sm">
          <Table.Header>
            <Table.Row>
              <Table.ColHeader>Concepto</Table.ColHeader>
              <Table.ColHeader className="text-center">Fecha</Table.ColHeader>
              <Table.ColHeader className="text-center">Total</Table.ColHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {aFeeServices.map((oService) => {
              return (
                <Table.Row key={oService.id}>
                  <Table.Col>{oService.concept}</Table.Col>
                  <Table.Col className="text-center">
                    {Helpers.formatDateFront(new Date(oService.receiptdate))}
                  </Table.Col>
                  <Table.Col className="text-center">
                    {parseInt(oService.subtotal)}
                  </Table.Col>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal>
    );
  };

  amountOfReceipts = (aServices) => {
    let nTotal = 0;
    aServices.map((oService) => {
      nTotal += parseInt(oService.subtotal);
    });
    return nTotal;
  };

  loadConfirmCreatePaymentModal = () => {
    this.setState({ isModalConfirmCreatePayment: true });
  };

  _handleCloseConfirmCreatePaymentModal = () => {
    this.setState({
      isModalConfirmCreatePayment: false,
    });
  };

  applyConfirmCreatePayment = async () => {
    let oPayment = {
      identificador: this.state.oClient.code,
      importe_abonado: this.state.nPaymentAmount,
    };

    let oClient = this.state.oClient;
    const debt = oClient["debt"];
    const resta = debt - oPayment.importe_abonado;
    oClient["debt"] = resta;

    this.props.processPayment(oPayment, "pagomanual", this.state.oClient.id);
    await this.setState({
      nPaymentAmount: 0,
      isModalCreatePayment: false,
      isModalConfirmCreatePayment: false,
      oClient,
    });
  };

  renderConfirmCreatePayment = () => {
    return (
      <Modal
        isOpen={this.state.isModalConfirmCreatePayment}
        handleActionModal={this.applyConfirmCreatePayment}
        handleCloseModal={this._handleCloseConfirmCreatePaymentModal}
        modalTitle="Confirmar"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={12}>¿Desea confirmar el pago?</Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  loadCreatePaymentModal = () => {
    this.setState({ isModalCreatePayment: true });
  };
  _handleCloseCreatePaymentModal = () => {
    this.setState({
      isModalCreatePayment: false,
      nPaymentAmount: 0,
    });
  };

  _handleChangePaymentAmount = (event) => {
    const { value } = event.target;
    if (!isNaN(value)) {
      this.setState({ nPaymentAmount: value });
    }
  };

  renderCreatePaymentModal = () => {
    const { oClient, nPaymentAmount } = this.state;
    return (
      <Modal
        isOpen={this.state.isModalCreatePayment}
        handleActionModal={this.loadConfirmCreatePaymentModal}
        handleCloseModal={this._handleCloseCreatePaymentModal}
        modalTitle="Cargar Pago"
        customStyles={{
          width: "550px",
        }}
      >
        <Grid.Row className="system-body">
          <Grid.Col width={6}>
            <Form.Group label="Nro de Socio">
              <Form.Input name="nroSocio" value={oClient.code} disabled />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Nombre">
              <Form.Input
                name="nombre"
                value={oClient.fullname.trim()}
                disabled
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Cuota">
              <Form.Input name="feevalue" value={oClient.feevalue} disabled />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={6}>
            <Form.Group label="Deuda">
              <Form.Input name="debt" value={oClient.debt} enabled />
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={12}>
            <Form.Group label="Importe">
              <Form.Input
                name="nPaymentAmount"
                value={nPaymentAmount}
                placeholder="0"
                onChange={this._handleChangePaymentAmount}
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Modal>
    );
  };

  _handleCheckedChange = (oEvent) => {
    let { oClient } = this.state;
    oClient[oEvent.target.name] = oEvent.target.checked;
    this.setState({ oClient });
    console.log(this.state.oClient);
  };
  _handleCheckedAttendChange = (oEvent) => {
    let { oClient } = this.state;
    oClient[oEvent.target.name] = (oEvent.target.checked ? 1 : 0);
    this.setState({ oClient });
    console.log(this.state.oClient);
  };

  handleChancePaymentObservation = (nIndexFee, nIndexPayment, sValue) => {
    let { aFees } = this.state;
    Object(aFees[nIndexFee].payments[nIndexPayment]).observation = sValue;
    this.setState({ aFees });
  };

  goToView = (nClientId, sRoute) => {
    this.props.getClient(nClientId);
    this.props.history.push(sRoute);
  };

  render = () => {
    const { oClient, clientIndex, aFees } = this.state;
    const { nAllRecords } = this.props.propsPayment;
    return oClient !== null ? (
      <Card>
        <Card.Header>
          <Card.Title className="mr-auto">Detalles de pagos</Card.Title>
          <SearchClient
            searchAction={this.props.getClientsSinglePagination}
            type={this.state.sSearchType}
          />
          <Button
            className="btn btn-primary ml-3"
            onClick={() => this.goToView(oClient.id, "/additionalServices")}
          >
            <Icon name="eye" className="mr-2" />
            Servicios Adicionales
          </Button>
          <SharedButton
            sIcon={"chevrons-up"}
            sLabel={"Cargar pago"}
            fAction={this.loadCreatePaymentModal}
          />
          <SaveButton saveAction={this._stopSave} />
          <CancelButton BackTo="/billing" />
        </Card.Header>
        {
          <Card.Body className="cardBodyBilling">
            <Grid.Row>
              <Grid.Col width={9}>
                <Grid.Row>
                  <Grid.Col width={3}>
                    <Form.Group label="Apellidos nombre">
                      <Form.Input
                        name="fullname"
                        value={
                          oClient.fullname.trimEnd()
                            ? oClient.fullname.trimEnd()
                            : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Grid.Col>
                  {oClient.spouse_name && (
                    <Grid.Col width={2}>
                      <Form.Group label="Cónyuge">
                        <Form.Input
                          name="spouse_name"
                          value={oClient.spouse_name ? oClient.spouse_name : ""}
                          placeholder=""
                          disabled
                          readOnly
                        />
                      </Form.Group>
                    </Grid.Col>
                  )}
                  <Grid.Col width={oClient.spouse_name ? 4 : 6}>
                    <Form.Group label="Dirección">
                      <Form.Input
                        name=""
                        value={
                          oClient.principal_address
                            ? oClient.principal_address
                            : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={3}>
                    <Form.Group label="Teléfono">
                      <Form.Input
                        name=""
                        value={
                          oClient.principal_phone ? oClient.principal_phone : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <div style={{ width: "100px", marginRight: "15px" }}>
                    <Form.Group label="Cédula">
                      <Form.Input
                        name=""
                        value={
                          oClient.identificationcard
                            ? oClient.identificationcard
                            : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </div>
                  <div style={{ width: "105px", marginRight: "15px" }}>
                    <Form.Group label="Desde">
                      <Form.Input
                        name=""
                        value={
                          oClient.affiliationdate
                            ? oClient.affiliationdate
                                .split("-")
                                .reverse()
                                .join("/")
                            : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </div>
                  <div style={{ width: "80px", marginRight: "15px" }}>
                    <Form.Group label="Socio">
                      <Form.Input
                        name=""
                        value={oClient.code ? oClient.code : ""}
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </div>
                  <div style={{ width: "80px", marginRight: "15px" }}>
                    <Form.Group label="Cuota">
                      <Form.Input
                        name="feevalue"
                        value={
                          oClient.feevalue
                            ? oClient.feevalue
                            : oClient.feevalue == 0
                            ? 0
                            : ""
                        }
                        placeholder=""
                        className="border-danger"
                        onChange={(e) => {
                          this._handleChange(e);
                        }}
                      />
                    </Form.Group>
                  </div>

                  <div style={{ width: "80px", marginRight: "15px" }}>
                    <Form.Group label="Deuda">
                      <Form.Input
                        name="debt"
                        value={
                          oClient.debt
                            ? oClient.debt
                            : oClient.debt == 0
                            ? 0
                            : ""
                        }
                        placeholder=""
                        className="border-info"
                        onChange={(e) => {
                          this._handleChange(e);
                        }}
                        /* disabled
                        readOnly */
                      />
                    </Form.Group>
                  </div>

                  <div style={{ width: "80px", marginRight: "15px" }}>
                    {/* Quedan algunos comentarios en el fichero ya que se esta trabajando progresivamente en este y se esta comentando lo que se puede usar proximamente */}
                    {/* Comentada la cuota mides para cuando se trabaje en esa parte proximamente */}
                    {/* {oClient.midespartner !== null ? (
                  <Form.Group label="Cuota mides">
                    <Form.Input
                      name=""
                      value={oClient.midesquota && oClient.midesquota}
                      placeholder=""
                      className="border-danger"
                      disabled
                    />
                  </Form.Group>
                ) : (
                  ""
                )} */}
                    {oClient.midespartner !== null ? (
                      <Form.Group label="Mides">
                        <Form.Input
                          name=""
                          value={oClient.midespartner + "%"}
                          placeholder=""
                          disabled
                          readOnly
                        />
                      </Form.Group>
                    ) : null}
                  </div>

                  <div width={1}>
                    <Form.Group className="text-white" label={"{}"}>
                      {/* <SaveButton saveAction={this._updateDebt} /> */}
                      <button
                        className="btn btn-primary"
                        onClick={this._updateDebt}
                      >
                        <Icon name="save" className="mr-2" /> Actualizar deuda
                      </button>
                    </Form.Group>
                  </div>

                  {/*  <Grid.Col width={10}></Grid.Col> */}
                  <Grid.Col width={2}>
                    <Form.Group className="text-white mt-3" label={"{}"}>
                      <label className="custom-switch">
                        <input
                          key={`stopPaying ${oClient.id}`}
                          id="stopPaying"
                          type="checkbox"
                          name="stopPaying"
                          className="custom-switch-input"
                          value={oClient.stopPaying}
                          defaultChecked={oClient.stopPaying}
                          onChange={this._handleCheckedChange}
                        />
                        <span className="custom-switch-indicator"></span>
                        <span className="custom-switch-description font-weight-bold">
                          Baja de tarjeta
                        </span>
                      </label>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Form.Group className="text-white" label={"{}"}>
                    <label className="custom-switch">
                      <input
                        key={`noAttend ${oClient.id}`}
                        id="no_attend"
                        type="checkbox"
                        name="no_attend"
                        className="custom-switch-input"
                        value={oClient.no_attend}
                        defaultChecked={oClient.no_attend}
                        onChange={this._handleCheckedAttendChange}
                      />
                      <span className="custom-switch-indicator"></span>
                      <span className="custom-switch-description font-weight-bold">
                        No atender
                      </span>
                    </label>
                  </Form.Group>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Button
                      color="warning"
                      className="d-flex py-3 ml-auto"
                      disabled={this.props.propsFee.dUpDate === null}
                      onClick={() =>
                        this.searchFees(this.props.propsFee.dUpDate)
                      }
                    >
                      <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
                    </Button>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Table className="table-sm">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader className=" col-2">
                            Meses
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center col-1">
                            Deuda del mes
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center">
                            Servicios
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center">
                            Deuda
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center">
                            <div className="row">
                              <div className="col-2">Fecha</div>
                              <div className="col-2">Pago</div>
                              <div className="col-6">Observación</div>
                              <div className="col-2"></div>
                            </div>
                          </Table.ColHeader>
                          {oClient.midespartner !== null ? (
                            <Table.ColHeader className="text-center col-1">
                              Cuota Mides
                            </Table.ColHeader>
                          ) : null}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {aFees &&
                          aFees.map((oFee, nIndexFee) => {
                            return (
                              <Table.Row key={oFee.id}>
                                <Table.Col className="col-2">
                                  {oFee.year} / {oFee.month_name}
                                </Table.Col>
                                <Table.Col className="text-center col-1">
                                  {parseInt(oFee.month_debt)}
                                </Table.Col>
                                <Table.Col className="text-center">
                                  {oFee.receipts.length > 0 ? (
                                    <div
                                      className="cursor-pointer"
                                      onClick={() =>
                                        this.loadServicesViewModal(
                                          oFee.receipts
                                        )
                                      }
                                    >
                                      {parseInt(
                                        this.amountOfReceipts(oFee.receipts)
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Table.Col>
                                <Table.Col className="text-center">
                                  {parseInt(oFee.total_debt)}
                                </Table.Col>
                                {oFee.payments !== null && (
                                  <>
                                    <Table.Col className="text-center">
                                      {oFee.payments.map(
                                        (oPayment, nIndexPayment) => (
                                          <div
                                            className={`row ${
                                              oPayment.rejected &&
                                              "payment-rejected"
                                            }`}
                                          >
                                            <div className="col-2 p-0">
                                              {oPayment.formated_date ?? "S/D"}
                                            </div>
                                            <div className="col-2 p-0">
                                              {oPayment.amount}
                                            </div>
                                            <div className="col-6 p-0">
                                              <Form.Input
                                                className="form-control-sm"
                                                name="observationPayment"
                                                value={oPayment.observation}
                                                placeholder="Observación"
                                                onChange={(oEvent) => {
                                                  this.handleChancePaymentObservation(
                                                    nIndexFee,
                                                    nIndexPayment,
                                                    oEvent.target.value
                                                  );
                                                }}
                                              />
                                            </div>
                                            <div className="col-1 text-center p-0 m-auto">
                                              {oPayment.rejected ? (
                                                <FontAwesomeIcon
                                                  icon={faBan}
                                                ></FontAwesomeIcon>
                                              ) : (
                                                <FontAwesomeIcon
                                                  onClick={() =>
                                                    this.cancelPayment(
                                                      oPayment.id
                                                    )
                                                  }
                                                  className="cursor-pointer"
                                                  icon={faTrash}
                                                ></FontAwesomeIcon>
                                              )}
                                              &nbsp;
                                              {oPayment.order_id ? (
                                                <FontAwesomeIcon
                                                  onClick={() =>
                                                    this.downloadInvoice(
                                                      oPayment.order_id
                                                    )
                                                  }
                                                  className="cursor-pointer"
                                                  icon={faFileAlt}
                                                ></FontAwesomeIcon>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </Table.Col>
                                  </>
                                )}
                                {oClient.midespartner !== null ? (
                                  <Table.Col className="text-center col-1">
                                    {oFee.amount_mides}
                                  </Table.Col>
                                ) : null}
                              </Table.Row>
                            );
                          })}
                      </Table.Body>
                    </Table>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Button
                      color="warning"
                      className="d-flex py-3 ml-auto"
                      disabled={this.props.propsFee.dDownDate === null}
                      onClick={() =>
                        this.searchFees(this.props.propsFee.dDownDate)
                      }
                    >
                      <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
                    </Button>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form.Group label="Observaciones">
                      <Form.Textarea
                        name="billingobservation"
                        placeholder=""
                        rows={6}
                        onChange={(e) => {
                          this._handleChange(e);
                        }}
                      >
                        {oClient.billingobservation &&
                        oClient.billingobservation !== "NULL"
                          ? oClient.billingobservation
                          : ""}
                      </Form.Textarea>
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
              {/* Parte decha de la pantalla */}
              <Grid.Col width={3}>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form.Group label="Periodo de pago">
                      <Form.Select
                        name="payment_period"
                        value={oClient.payment_period}
                        onChange={this._handleChange}
                      >
                        <option value={0}>Mes corriente</option>
                        <option value={1}>Mes vencido</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group label="Modo de pago">
                      <Form.Select
                        name="pay_method"
                        value={oClient.pay_method ? oClient.pay_method : ""}
                        onChange={this._handleChange}
                      >
                        <option value={""}></option>
                        <option value={"Abitab/RedPagos"}>
                          Abitab / Red pagos
                        </option>
                        <option value={"Transferencia"}>Transferencia</option>
                        <option value={"Sistarbank"}>Sistarbanc</option>
                        <option value={"Facilpago"}>Tarjeta de crédito</option>
                        <option value={"PagoManual"}>Pago manual</option>
                        <option value={"NoPaga"}>No paga</option>
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Débito">
                      <Form.Input
                        name="card_branch"
                        value={
                          oClient.card_branch &&
                          oClient.pay_method === "Facilpago"
                            ? oClient.card_branch
                            : ""
                        }
                        placeholder=""
                        onChange={this._handleChange}
                        disabled={
                          oClient.pay_method !== "Facilpago" ? true : false
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Tarjeta No.">
                      <Form.Input
                        name="card_number"
                        value={
                          oClient.card_number &&
                          oClient.pay_method === "Facilpago"
                            ? oClient.card_number
                            : ""
                        }
                        placeholder=""
                        onChange={this._handleChange}
                        disabled={
                          oClient.pay_method !== "Facilpago" ? true : false
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Propietario Tarjeta">
                      <Form.Input
                        name="card_owner_name"
                        value={
                          oClient.card_owner_name &&
                          oClient.pay_method === "Facilpago"
                            ? oClient.card_owner_name
                            : ""
                        }
                        placeholder=""
                        onChange={this._handleChange}
                        disabled={
                          oClient.pay_method !== "Facilpago" ? true : false
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Propietario Tarjeta Cédula">
                      <Form.Input
                        name="card_owner_ci"
                        value={
                          oClient.card_owner_ci &&
                          oClient.pay_method === "Facilpago"
                            ? oClient.card_owner_ci
                            : ""
                        }
                        placeholder=""
                        onChange={this._handleChange}
                        disabled={
                          oClient.pay_method !== "Facilpago" ? true : false
                        }
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Aparato">
                      <Form.Input
                        name=""
                        value={oClient.device_model ? oClient.device_model : ""}
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label="Pila">
                      <Form.Input
                        name=""
                        value={
                          oClient.formated_changebatterybracelet
                            ? oClient.formated_changebatterybracelet
                            : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <Form.Group label="Cambio botón en oficina">
                      <Form.Input name="" value="" placeholder="" />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Correo para envío de factura">
                      <Form.Input
                        name="email_billing"
                        value={
                          oClient.email_billing ? oClient.email_billing : ""
                        }
                        onChange={this._handleChange}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={12}>
                    <Form.Group label="Correo">
                      <Form.Input
                        name=""
                        value={
                          oClient.emails && oClient.emails.length > 0
                            ? oClient.emails[0].email
                            : ""
                        }
                        placeholder=""
                        disabled
                        readOnly
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row>
              <div
                className="billingScrollerSector col col-12 d-flex bg-yellow-lighter py-4"
                onMouseEnter={(e) => {
                  var x = window.scrollX;
                  var y = window.scrollY;
                  window.onscroll = function () {
                    window.scrollTo(x, y);
                  };
                }}
                onMouseLeave={(e) => {
                  window.onscroll = function () {};
                }}
                onWheel={(e) => {
                  if (e.deltaY > 0) {
                    this.searchByIndex(this.state.clientIndex - 1);
                  } else {
                    this.searchByIndex(this.state.clientIndex + 1);
                  }
                }}
              >
                <div className="mr-auto d-flex justify-content-center align-items-center">
                  <Form.Input
                    className="bg-transparent border-none font-weight-bold w-9"
                    value="Registro"
                    readOnly
                    disabled
                  />
                  <Button
                    color="default mr-2"
                    disabled={clientIndex === 1 ? true : false}
                    onClick={() =>
                      this.props.getClientsSinglePagination(null, 1)
                    }
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft}></FontAwesomeIcon>
                  </Button>
                  <Button
                    color="default mr-2"
                    disabled={clientIndex === 1 ? true : false}
                    onClick={() => this.searchByIndex(clientIndex - 1)}
                  >
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                  </Button>
                  <Form.Input
                    name=""
                    value={clientIndex}
                    placeholder=""
                    className="form-control text-center mr-2"
                    onChange={(e) => this._handleIndexChange(e)}
                  />
                  <Button
                    color="default mr-2"
                    disabled={clientIndex === nAllRecords ? true : false}
                    onClick={() => this.searchByIndex(clientIndex + 1)}
                  >
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                  </Button>
                  <Button
                    color="default mr-2"
                    disabled={clientIndex === nAllRecords ? true : false}
                    onClick={() => this.searchByIndex(nAllRecords)}
                  >
                    <FontAwesomeIcon
                      icon={faAngleDoubleRight}
                    ></FontAwesomeIcon>
                  </Button>
                  <Form.Input
                    className="bg-transparent border-none font-weight-bold w-9"
                    value={"de " + nAllRecords}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </Grid.Row>
            <PaymentsList
              isModalOpen={this.state.isModalPaymentsOpen}
              onClose={this.closePaymentsModal}
              aPayments={this.state.aPayments}
            />
            {this.renderCreatePaymentModal()}
            {this.renderConfirmCreatePayment()}
            {this.renderServicesViewModal()}
            {this.renderModalReason()}
          </Card.Body>
        }

        <ModalConfirm
          isOpen={this.state.isModalCancelGenerateFeesModal}
          handleCloseModal={this._handleCloseCancelGenerateFeesModal}
          modalTitle="Confirmar"
          customStyles={{
            width: "550px",
          }}
        >
          <Grid.Row className="system-body">
            <Grid.Col width={12}>
              NO puede realizar esta accion si se cambio en monto de la DEUDA
            </Grid.Col>
          </Grid.Row>
        </ModalConfirm>

        <Modal
          isOpen={this.state.isModalConfirmGenerateFeesModal}
          handleActionModal={this._save}
          handleCloseModal={this._handleCloseConfirmGenerateFeesModal}
          modalTitle="Confirmar"
          customStyles={{
            width: "550px",
          }}
        >
          <Grid.Row className="system-body">
            <Grid.Col width={12}>
              ¿Desea modificar el importe de la DEUDA?
            </Grid.Col>
          </Grid.Row>
        </Modal>
      </Card>
    ) : null;
  };

  searchFees = (dNextDate) => {
    this.props.getFeesByClientAndRange(this.state.oClient.id, dNextDate);
  };
}

const mapStateToProps = (state) => ({
  oShared: state.sharedReducer,
  propsPayment: state.paymentsReducer,
  propsClient: state.clientsReducer,
  propsFee: state.feesReducer,
});

const mapDispatchToProps = (dispatch) => ({
  getClient: (idClient) => {
    dispatch(actionGetClient(idClient));
  },
  sendMessage: (sMessage, sMessageType) => {
    dispatch(actionMessage(sMessage, sMessageType));
  },
  CleanClientDetails: () => {
    dispatch(actionCleanClientDetails());
  },
  update: (oClient) => {
    dispatch(actionUpdateClientPayments(oClient));
  },
  getClientsSinglePagination: (nIdClient, nItemIndex) => {
    dispatch(actionGetClientSinglePagination(nIdClient, nItemIndex));
  },
  getFeesByClientAndRange: (nIdClient, nYear, nMonth) => {
    dispatch(actionGetFeesByClientAndRange(nIdClient, nYear, nMonth));
  },
  processPayment: (oPayment, sPaymethod, nIdClient = 0) => {
    dispatch(actionProcessPayments(oPayment, sPaymethod, nIdClient));
  },
  cancelPayment: (nPaymentId, sReason, nClientId) => {
    dispatch(actionCancelPayment(nPaymentId, sReason, nClientId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingUpdate);
